import React from "react";

import { Button, Tooltip } from "@iwoca/orion";
import cn from "classnames";
import { Field } from "formik";
import { Link } from "react-router";

import { redirectUser } from "../../authentication";
import { getRedirectToLink } from "../../LoginWithLink/getRedirectLink";
import { InformationBanner } from "../Components/InformationBanner/InformationBanner";
import {
  OtpCodeField,
  OtpCodeForm,
} from "../Components/OtpCodeForm/OtpCodeForm";
import styles from "../MultifactorAuth.module.css";

export const AuthenticateMultifactorAuth = () => {
  return (
    <div className={styles.modalLarge}>
      <h2 className={styles.headlineMedium}>Please confirm it's you</h2>
      <InformationBanner type="safety">
        <b>You need to confirm it's you using your authenticator app.</b>
      </InformationBanner>
      <p className={styles.instructionText}>Enter the 6-digit code</p>
      <OtpCodeForm
        type="log in"
        onSuccess={() => redirectUser({ redirectUrl: getRedirectToLink() })}
      >
        {(onClick: () => void) => (
          <>
            <OtpCodeField onClick={onClick} name="otpCode" />
            <div className={styles.trustDevice}>
              <Field
                type="checkbox"
                name="remember_device"
                className={styles.trustDeviceCheckbox}
              />{" "}
              Trust this device
              <div className={styles.tooltipContainer}>
                <Tooltip
                  className={styles.tooltip}
                  content={
                    "Trusting this device will remember this device for 30 days.\n\nAfter that you will need to enter a new authenticator code when you next log in."
                  }
                />
              </div>
            </div>
            <Button type="submit" className={styles.submitButtonShort}>
              Confirm
            </Button>
          </>
        )}
      </OtpCodeForm>

      <hr className={styles.horizontalLine} />
      <p className={cn(styles.lightSubtitleText, styles.firstLightSubtitle)}>
        Cant find your authenticator code? Please check you’re on the right
        phone.
      </p>
      <p className={styles.lightSubtitleText}>
        If you haven’t set up authentication yet{" "}
        <Link to="/login/two-factor/setup" className={styles.link}>
          set it up now.
        </Link>
      </p>
    </div>
  );
};
