import React from "react";

import styles from "./SetupStep.module.css";

export const SetupStep = ({
  stepNumber,
  stepText,
  stepSubtitle,
  stepBody,
  children,
}: {
  stepNumber: number;
  stepText: string;
  stepSubtitle?: string;
  stepBody?: any;
  children?: any;
}) => {
  return (
    <>
      <div className={styles.stepHeading}>
        <div className={styles.stepIndicator}>
          Step {stepNumber} <span className={styles.hyphen}>-</span>
        </div>
        <div className={styles.stepText}>
          {stepText}
          {stepSubtitle && (
            <div className={styles.stepSubtitle}>{stepSubtitle}</div>
          )}
          {stepBody}
        </div>
      </div>
      {children}
    </>
  );
};
