import React, { ReactNode } from "react";

import { IwocaLogo } from "@iwoca/orion";

import styles from "./Login.module.css";

const LoginContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className={styles.loginWrapper}>
      <div className={styles.loginEntry}>
        <IwocaLogo className={styles.logo} />
        {children}
      </div>
    </div>
  );
};

export default LoginContainer;
