import { useState, useCallback } from "react";

import { getCookie } from "./cookieHelper";

type useFetchApiArgs = {
  endpoint: string;
  fetchConfig?: RequestInit;
  initFetch?: boolean;
};
type FetchApiOutput<T> = {
  status?: number;
  payload: T | undefined;
  hasError: boolean;
};
type useFetchApiPostOutput<T> = FetchApiOutput<T> & {
  isLoading: boolean;
  triggerFetch: (data: object) => Promise<FetchApiOutput<T>>;
};
type useFetchApiGetOutput<T> = FetchApiOutput<T> & {
  isLoading: boolean;
  triggerFetch: () => Promise<FetchApiOutput<T>>;
};
export const usePostApi = <T>({
  endpoint,
}: useFetchApiArgs): useFetchApiPostOutput<T> => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [status, setStatus] = useState<number | undefined>();
  const [payload, setPayload] = useState();

  const fetchData = useCallback(
    async (data: object): Promise<FetchApiOutput<T>> => {
      setLoading(true);

      const response = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify(data),
        mode: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken") as string,
        },
      });
      const responseStatus = response.status;
      setStatus(responseStatus);

      let responsePayload;
      try {
        responsePayload = await response.json();
      } catch (error) {
        if (!response.ok) {
          setLoading(false);
          setHasError(true);
          responsePayload = error;
          return {
            hasError: true,
            payload: undefined,
            status: responseStatus,
          };
        }
      }
      setPayload(responsePayload);

      if (!response.ok) {
        setHasError(true);
        setLoading(false);
        return {
          hasError: true,
          payload: undefined,
          status: responseStatus,
        };
      }

      setLoading(false);
      return {
        payload: responsePayload,
        hasError: false,
        status: responseStatus,
      };
    },
    [endpoint],
  );

  return {
    isLoading,
    status,
    payload,
    hasError,
    triggerFetch: fetchData,
  };
};

export const useGetApi = <T>({
  endpoint,
}: useFetchApiArgs): useFetchApiGetOutput<T> => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [status, setStatus] = useState<number | undefined>();
  const [payload, setPayload] = useState();

  const fetchData = useCallback(async (): Promise<FetchApiOutput<T>> => {
    setLoading(true);

    const response = await fetch(endpoint, {
      method: "GET",
      mode: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken") as string,
      },
    });
    const responseStatus = response.status;
    setStatus(responseStatus);

    let responsePayload;
    try {
      responsePayload = await response.json();
    } catch (error) {
      if (!response.ok) {
        setLoading(false);
        setHasError(true);
        responsePayload = error;
        return {
          hasError: true,
          payload: undefined,
          status: responseStatus,
        };
      }
    }
    setPayload(responsePayload);

    if (!response.ok) {
      setHasError(true);
      setLoading(false);
      return {
        hasError: true,
        payload: undefined,
        status: responseStatus,
      };
    }

    setLoading(false);
    return {
      payload: responsePayload,
      hasError: false,
      status: responseStatus,
    };
  }, [endpoint]);

  return {
    isLoading,
    status,
    payload,
    hasError,
    triggerFetch: fetchData,
  };
};
