import { LapiError } from "@iwoca/lapi-client/lendingAPI";
import { AlertBox } from "@iwoca/orion";

import { TCopy } from "../login/Login";

export const LoginErrorMessage = ({
  error,
  copy,
}: {
  error: LapiError;
  copy?: TCopy["error"];
}) => {
  try {
    const primaryError = JSON.parse(error.message).errors[0];

    switch (primaryError.code) {
      case "AuthenticationFailed":
        return (
          <AlertBox variant="failure" data-testid="error-message">
            {copy?.loginEmailOrPassWrong ||
              "The email or password you entered is incorrect"}
          </AlertBox>
        );
      case "MultipleIwocaPaySellers":
        return (
          <AlertBox variant="failure" data-testid="error-message">
            <div>
              <p>
                {copy?.iwocapayAccountProblem ||
                  "Oops we've spotted a problem with your account."}
              </p>
              <p>
                {copy?.iwocapayContact ||
                  "A member of our iwocaPay team will be in touch to help fix this, or if you'd prefer you can call us on 0203 778 0549."}
              </p>
            </div>
          </AlertBox>
        );
    }
  } catch (e) {}

  return (
    <AlertBox variant="failure" data-testid="error-message">
      {copy?.loginError || "Sorry, something’s gone wrong. Try again later?"}
    </AlertBox>
  );
};
