import { getCookie } from "../cookieHelper";
import { isIwocaDE } from "../countryHelper";

export type UserType =
  | "customer"
  | "introducer"
  | "staff"
  | "partner_developer"
  | "introducer_customer"
  | "seller_access_user"
  | "personal_guarantor_access_user"
  | null;
export type ProfileResponse = {
  data: {
    user_id: number;
    user_type: UserType;
  };
};
type Args = {
  redirectUrl?: string;
  isTestingLoggedIn?: boolean;
};
export const REDIRECT_INFO_LOG_MESSAGE = "Redirecting logged in user";
export const UNEXPECTED_REDIRECT_ERROR_LOG_MESSAGE =
  "Unexpected error while redirecting";
export const UNEXPECTED_CUSTOMER_PROFILE_ERROR_LOG_MESSAGE =
  "Unexpected customer profile for login";
export const redirectUser = async ({
  redirectUrl,
  isTestingLoggedIn = false,
}: Args = {}): Promise<void> => {
  if (redirectUrl && !isTestingLoggedIn) {
    window.location.href = redirectUrl;
    return;
  }

  const response = await fetch("/api/lending/edge/profile/", {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "X-CSRFToken": getCookie("csrftoken") as string,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  if (isTestingLoggedIn && redirectUrl && response.ok) {
    window.location.href = redirectUrl;
    return;
  }

  if (!response.ok && isTestingLoggedIn) {
    throw response.status;
  }

  try {
    const payload: ProfileResponse = await response.json();
    if (
      payload.data.user_type === "customer" ||
      payload.data.user_type === "introducer_customer" ||
      payload.data.user_type === "seller_access_user" ||
      payload.data.user_type === "personal_guarantor_access_user"
    ) {
      window.location.href = "/my_account";
      return;
    }

    if (payload.data.user_type === "introducer") {
      if (isIwocaDE()) {
        window.location.href = "/partner/account";
        return;
      }

      window.location.href = "/introducer/account";
      return;
    }

    if (payload.data.user_type === "partner_developer") {
      window.location.href = "/developer/portal";
      return;
    }

    if (payload.data.user_type === "staff") {
      window.location.href = "/backyard";
      return;
    }
  } catch (error) {
    if (!response.ok && isTestingLoggedIn) {
      throw error;
    }
  }

  window.location.href = "/";
};
