import { useState } from "react";

import { LapiError } from "@iwoca/lapi-client/lendingAPI";

export function usePost<
  GPostFn extends ({ body }: { body: any }) => Promise<any>,
>(fn: GPostFn) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<LapiError>();

  const post = async (body: any) => {
    setError(undefined);
    setIsLoading(true);

    try {
      return await fn({ body });
    } catch (err) {
      setError(err as LapiError);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    post,
    isLoading,
    error,
  };
}
