import React from "react";

import styles from "./LoadingSpinner.module.css";

type Props = {
  ["data-testid"]?: string;
};
export const LoadingSpinner: React.FunctionComponent<Props> = (props) => (
  <div data-testid={props["data-testid"]} className={styles.LoadingSpinner}>
    'Loading...'
  </div>
);
