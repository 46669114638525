import React from "react";

import { Trans } from "@lingui/react/macro";

type Props = {
  errorCode: string;
};
const NewPasswordApiError: React.FunctionComponent<Props> = ({ errorCode }) => {
  switch (errorCode) {
    case "PasswordMismatchError":
      return <Trans>The two password fields didn't match.</Trans>;
    case "PasswordLengthError":
      return (
        <Trans>
          Password is too short. It must contain at least 8 character(s).
        </Trans>
      );
    case "PasswordUppercaseError":
      return (
        <Trans>
          Password must contain 1 or more uppercase characters (A-Z).
        </Trans>
      );
    case "PasswordLowercaseError":
      return (
        <Trans>
          Password must contain 1 or more lowercase characters (a-z).
        </Trans>
      );
    case "PasswordDigitsError":
      return <Trans>Password must contain 1 or more numbers (0-9).</Trans>;
    case "PasswordSymbolsError":
      return <Trans>Password must contain 1 or more symbols.</Trans>;
    default:
      return <Trans>Sorry, something’s gone wrong. Try again later?</Trans>;
  }
};
export default NewPasswordApiError;
