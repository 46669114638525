import React from "react";

import styles from "./InformationBanner.module.css";
import informationSVG from "../../../../assets/information.svg";
import padlockSVG from "../../../../assets/padlock.svg";

export const InformationBanner = ({
  type,
  children,
}: {
  type: "safety" | "notice";
  children: any;
}) => {
  return (
    <div className={styles.notice}>
      <img
        className={styles.padlock}
        src={type === "safety" ? padlockSVG : informationSVG}
        width="16"
        height="auto"
        alt=""
      />
      <p className={styles.lightNoticeText}>{children}</p>
    </div>
  );
};
