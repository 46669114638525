import { i18n } from "@lingui/core";

export const locales = {
  en: "English",
  de: "German",
};
export const defaultLocale = "de";

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string) {
  const { messages } = await import(`./locales/${locale}/messages.ts`);
  i18n.loadAndActivate({ locale, messages });
}
