import React, { useState, useCallback, FormEvent } from "react";

import { AlertBox, Button, Input, Label } from "@iwoca/orion";
import { Trans } from "@lingui/react/macro";

import { isValidEmail } from "../LoginHelpers";
import {
  useResetPasswordApi,
  SuccessResponse,
  ErrorResponse,
} from "./useResetPasswordApi";
import greenCheckSVG from "../../assets/checkmark.svg";
import styles from "../CommonLogin.module.css";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import LoginContainer from "../LoginContainer";

const StaffForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const { status, hasError, isLoading, payload, triggerFetch } =
    useResetPasswordApi({
      endpoint: "/password/reset/",
    });

  const handleForgotPassword = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      const isEmailValid = isValidEmail(email);
      if (!isEmailValid) {
        setIsEmailInvalid(true);
      }

      if (isEmailValid) {
        triggerFetch({ email });
      }
    },
    [email, triggerFetch],
  );

  const hasAccountNotFoundError =
    (payload as ErrorResponse)?.email?.[0]?.code === "account_not_found";
  const hasSuccessResponse = (payload as SuccessResponse)?.success;
  const hasSuccessStatus = status === 200;
  return (
    <LoginContainer>
      <form
        className={styles.loginWithPassword}
        onSubmit={handleForgotPassword}
      >
        <Label htmlFor="email-input" className={styles.loginLabel}>
          <Trans>Email</Trans>
        </Label>
        <Input
          className="fs-exclude"
          data-testid="email-input"
          placeholder="name@provider.com"
          id="email-input"
          onChange={(event) => {
            setIsEmailInvalid(false);
            setEmail(event.target.value);
          }}
          onBlur={() => {
            if (!isValidEmail(email)) {
              setIsEmailInvalid(true);
            }
          }}
        />
        {isEmailInvalid ? (
          <AlertBox
            variant="failure"
            data-testid="error-message"
            className={styles.errorBox}
          >
            <Trans>Are you sure? This is not a valid email address</Trans>
          </AlertBox>
        ) : null}

        <p className={styles.instructionsLong}>
          <Trans>
            Enter your email and we will send a link to reset your password.
          </Trans>
        </p>

        <Button
          disabled={isEmailInvalid || isLoading}
          type="submit"
          variant="primary"
          className={styles.submitButton}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : !hasError && hasSuccessStatus ? (
            <Trans>Retry</Trans>
          ) : (
            <Trans>Reset my password</Trans>
          )}
        </Button>

        {!hasError && hasSuccessStatus && hasSuccessResponse ? (
          <AlertBox
            variant="success"
            className={styles.goodBox}
            data-testid="error-message"
          >
            <img src={greenCheckSVG} className={styles.checkmark} alt="" />
            <Trans>Check your email inbox; we've sent a link</Trans>
          </AlertBox>
        ) : null}

        {!hasError && hasSuccessStatus && hasAccountNotFoundError ? (
          <AlertBox
            variant="failure"
            data-testid="error-message"
            className={styles.errorBox}
          >
            <Trans>There is no account with that email address</Trans>
          </AlertBox>
        ) : null}

        {(hasError && !hasSuccessStatus) ||
        (!hasError &&
          hasSuccessStatus &&
          !hasSuccessResponse &&
          !hasAccountNotFoundError) ? (
          <AlertBox
            variant="failure"
            data-testid="error-message"
            className={styles.errorBox}
          >
            <Trans>Sorry, something’s gone wrong. Try again later?</Trans>
          </AlertBox>
        ) : null}
      </form>
    </LoginContainer>
  );
};

export default StaffForgotPassword;
