import React from "react";

type Props = {
  className?: string;
};
const TokenFailureSVG: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.5" cx="120" cy="120" r="120" fill="#EEF3F7" />
      <g clipPath="url(#clip0)">
        <path
          d="M95.5862 148.833C114.78 153.976 134.508 142.586 139.651 123.392C144.794 104.198 133.404 84.4697 114.21 79.3267C95.0168 74.1838 75.2882 85.5739 70.1452 104.768C65.0022 123.962 76.3926 143.69 95.5862 148.833Z"
          stroke="#0C2340"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M92.1735 161.571C118.552 168.639 145.666 152.985 152.734 126.606C159.802 100.227 144.148 73.1132 117.769 66.0451C91.3908 58.9769 64.2769 74.6312 57.2087 101.01C50.1406 127.389 65.7948 154.503 92.1735 161.571Z"
          stroke="#0C2340"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M129.946 157.065L161.462 185.012L171.778 182.901L174.216 173.803L142.701 145.857"
          stroke="#0C2340"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M110.993 91.3379C123.547 94.7019 131.007 107.622 127.643 120.176"
          stroke="#FB534A"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="150.698"
            height="150.698"
            fill="white"
            transform="translate(67.0034 28.0654) rotate(15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TokenFailureSVG;
