export const useMultifactorAuthKey = (authToken?: string) => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const authKey = authToken || params.get("k");

  // if they don't have an auth key, we need them to get one to continue
  if (authKey === null || authKey === "") {
    window.location.href = "/login";
  }

  return {
    multifactorAuthKey: authKey,
  };
};
