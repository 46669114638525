import React from "react";

type Props = {
  className?: string;
};
const TokenUsedSVG: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.5" cx="120" cy="120" r="120" fill="#EEF3F7" />
      <path
        d="M65.1919 151.658L74.6105 146.948H159.378L168.797 151.658V175.204H65.1919V151.658Z"
        stroke="#0C2340"
        strokeWidth="5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.7733 175.204H178.215"
        stroke="#0C2340"
        strokeWidth="5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.994 48.0527V66.8899"
        stroke="#FB534A"
        strokeWidth="5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.959 78.6629L173.506 66.8896"
        stroke="#FB534A"
        strokeWidth="5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.0291 78.6629L60.4825 66.8896"
        stroke="#FB534A"
        strokeWidth="5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.959 109.274C149.959 91.0487 135.219 76.3086 116.994 76.3086C98.7692 76.3086 84.0291 91.0487 84.0291 109.274V146.948H149.959V109.274Z"
        stroke="#0C2340"
        strokeWidth="5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.138 88.6465C130.227 88.6465 137.621 96.0401 137.621 105.129"
        stroke="#0C2340"
        strokeWidth="5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TokenUsedSVG;
