export function getRedirectToLink() {
  const urlParams = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );
  const { redirect_to, persist_params } = urlParams;
  if (!redirect_to) return undefined;
  if (persist_params !== "1") return redirect_to;

  return buildUrl(redirect_to);
}

const buildUrl = (redirectTo: string) => {
  const {
    origin,
    pathname,
    searchParams: redirectParams,
  } = new URL(
    redirectTo,
    window.location.origin, // fallback if redirect_to doesn't contain origin
  );

  const queryParams = buildQueryString(redirectParams);

  const targetUrl = `${origin}${pathname}`;
  return queryParams ? `${targetUrl}${queryParams}` : targetUrl;
};

const buildQueryString = (redirectParams: URLSearchParams) => {
  const urlParams = buildWhiteListedSearchParams();

  const allSearchParams = new URLSearchParams({
    ...Object.fromEntries(redirectParams),
    ...Object.fromEntries(urlParams),
  });

  const queryString = allSearchParams.toString();

  return queryString ? "?" + queryString : "";
};

const WHITE_LISTED_PARAMS = [
  "code",
  "hmac",
  "host",
  "shop",
  "state",
  "timestamp",
];

const buildWhiteListedSearchParams = () => {
  const params = new URLSearchParams(window.location.search);
  const hydratedSearchParams = new URLSearchParams();

  for (const WHITE_LISTED_PARAM of WHITE_LISTED_PARAMS) {
    const param = params.get(WHITE_LISTED_PARAM);
    if (param) hydratedSearchParams.append(WHITE_LISTED_PARAM, param);
  }
  return hydratedSearchParams;
};
