import React from "react";

import { tracking } from "@iwoca/frontend-tracking-library";
import { useParams } from "react-router";

import { redirectUser } from "../authentication";
import { getRedirectToLink } from "./getRedirectLink";
import TokenVerification from "../TokenViews/TokenVerification";

export type LoginSuccessPayload = {
  data: { next?: string; state_key: string };
};
const LoginTokenVerification = () => {
  const { token } = useParams();

  const handleSuccess = (payload: LoginSuccessPayload) => {
    if (getRedirectToLink() === "/account/link-open-banking/") {
      tracking.ampli.brokerObUserLoggedIn();
    }
    redirectUser({
      redirectUrl: getRedirectToLink() || payload?.data?.next,
    });
  };

  return (
    <TokenVerification<LoginSuccessPayload>
      token={token || ""}
      verificationUrl="/api/lending/edge/passwordless/email/login/"
      requestNewTokenUrl="/login/passwordless/email"
      onSuccess={handleSuccess}
    />
  );
};

export default LoginTokenVerification;
