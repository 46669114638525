import React from "react";

import { Trans } from "@lingui/react/macro";

import TokenContainer from "./TokenContainer";
import styles from "./TokenContainer.module.css";
import TokenFailureSVG from "../../assets/TokenAssets/TokenFailureSVG";

const TokenFailure: React.FunctionComponent = () => {
  return (
    <TokenContainer data-testid="token-failure-view">
      <TokenFailureSVG className={styles.tokenIllustration} />

      <h1 className={styles.tokenResponseTitle}>
        <Trans>Hmm, something's gone wrong</Trans>
      </h1>

      <div className={styles.tokenResponseDescription}>
        <Trans>Sorry about that; we're looking into it. Try again later?</Trans>
      </div>

      <a href="/" className={styles.tokenResponseCTA}>
        <Trans>Return to the homepage</Trans>
      </a>
    </TokenContainer>
  );
};

export default TokenFailure;
