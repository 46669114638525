import React, { PropsWithChildren, useEffect } from "react";

import { useNavigate } from "react-router";

import TokenExpired from "./TokenExpired";
import TokenFailure from "./TokenFailure";
import TokenLoading from "./TokenLoading";
import TokenUsed from "./TokenUsed";
import { usePostApi } from "../../fetchApi.hook";

type ErrorResponse = {
  code: string;
  detail: string;
  meta?: any;
  source?: { parameter: string; pointer: string };
};
type ErrorPayload = {
  errors: ErrorResponse[];
};

type Props<SuccessResponse> = {
  token: string;
  verificationUrl: string;
  onSuccess: (response: SuccessResponse) => void;
  requestNewTokenUrl: string;
};

const TokenVerification = <SuccessResponse,>({
  token,
  verificationUrl,
  onSuccess,
  requestNewTokenUrl,
}: PropsWithChildren<Props<SuccessResponse>>) => {
  const navigate = useNavigate();
  const { status, hasError, triggerFetch, payload } = usePostApi<
    SuccessResponse | ErrorPayload
  >({
    endpoint: verificationUrl,
  });

  type LoginError = {
    code: string;
    detail: string;
    meta: any;
  } | null;

  type LoginResponse = {
    errors?: LoginError[];
    data?: {
      state_key: string;
    };
  };

  useEffect(() => {
    if (!payload) return;
    if ("errors" in (payload as LoginResponse)) {
      const error = (payload as LoginResponse)?.errors?.[0] as LoginError;

      const searchParams = new URLSearchParams(window.location.search);
      if (error!.code === "TwoFactorSetupRequired") {
        searchParams.set("k", error!.meta.authentication_token);
        navigate(
          `/login/two-factor/setup/?${decodeURIComponent(
            searchParams.toString(),
          )}`,
        );
      }

      if (error!.code === "TwoFactorAuthenticationRequired") {
        searchParams.set("k", error!.meta.authentication_token);
        navigate(
          `/login/two-factor/authenticate/?${decodeURIComponent(
            searchParams.toString(),
          )}`,
        );
      }
    }
  }, [payload]);

  useEffect(() => {
    const verifyLogin = async () => {
      if (token) {
        try {
          // this is wrapped in a try catch to avoid blocking user if fails
          const encodedUserId = token.split("-")?.[0];
          const userId = atob(encodedUserId);
        } catch (e) {}

        const { hasError, payload } = await triggerFetch({
          data: { token },
        });
        if (!hasError) onSuccess(payload as SuccessResponse);
      }
    };
    verifyLogin();
  }, [token, triggerFetch, onSuccess]);

  if (hasError && status !== 400) {
    return <TokenFailure />;
  }

  if (
    hasError &&
    (payload as ErrorPayload)?.errors?.[0]?.code === "InvalidToken"
  ) {
    return <TokenUsed ctaURL={requestNewTokenUrl} />;
  }

  if (
    hasError &&
    (payload as ErrorPayload)?.errors?.[0]?.code === "TokenExpired"
  ) {
    return <TokenExpired ctaUrl={requestNewTokenUrl} />;
  }

  return <TokenLoading />;
};

export default TokenVerification;
