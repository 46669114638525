import { useEffect } from "react";

import { tracking } from "@iwoca/frontend-tracking-library";
import { useLocation } from "react-router";

import { getRedirectToLink } from "../../Login/LoginWithLink/getRedirectLink";

export function useAmplitudeTracking() {
  const amplitudeLoaded = tracking.ampli.isLoaded;
  const { pathname } = useLocation();

  useEffect(() => {
    tracking.initialise({
      environment: ["www.iwoca.co.uk", "www.iwoca.de"].includes(
        window.location.host,
      )
        ? "production"
        : "development",
    });
  }, []);

  useEffect(() => {
    const redirectTo = getRedirectToLink();
    if (
      redirectTo === "/account/link-open-banking/" &&
      amplitudeLoaded &&
      pathname === "/login/passwordless/email"
    ) {
      tracking.ampli.brokerObUserRedirectedToLogin();
    }
  }, [amplitudeLoaded]);
}
