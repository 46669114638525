import React from "react";

import { Trans } from "@lingui/react/macro";
import { Link } from "react-router";

import TokenContainer from "./TokenContainer";
import styles from "./TokenContainer.module.css";
import TokenUsedSVG from "../../assets/TokenAssets/TokenUsedSVG";
const TokenUsed = ({ ctaURL }: { ctaURL: string }) => {
  return (
    <TokenContainer data-testid="token-used-view">
      <TokenUsedSVG className={styles.tokenIllustration} />

      <h1 className={styles.tokenResponseTitle}>
        <Trans>Looks like you've used that link before</Trans>
      </h1>

      <div className={styles.tokenResponseDescription}>
        <Trans>You can only use each link once, unfortunately.</Trans>
      </div>

      <Link className={styles.tokenResponseCTA} to={ctaURL}>
        <Trans>Start again with a new link</Trans>
      </Link>
    </TokenContainer>
  );
};

export default TokenUsed;
