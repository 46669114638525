import { useState, FormEvent } from "react";

import {
  fetchPostIwocapayNotifications,
  fetchPostPasswordLogin,
} from "@iwoca/lapi-client/edge";
import { LapiError } from "@iwoca/lapi-client/lendingAPI";
import { AlertBox, Button, Input, Label } from "@iwoca/orion";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import cn from "classnames";
import { useLocation, useNavigate, Link } from "react-router";

import { usePost } from "../../hooks/usePost";
import { LoginErrorMessage } from "../../lib/LoginErrorMessage/LoginErrorMessage";
import { redirectUser } from "../authentication";
import styles from "../CommonLogin.module.css";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import LoginContainer from "../LoginContainer";
import { isValidEmail } from "../LoginHelpers";
import { getRedirectToLink } from "../LoginWithLink/getRedirectLink";
import {
  determineTwoFactorRedirect,
  reportIwocaPayBrokenAccount,
} from "../MultifactorAuth/utils/ErrorHandling";

const LoginWithPassword = () => {
  const [email, setEmail] = useState("");
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [password, setPassword] = useState("");
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const { search } = useLocation();
  const navigate = useNavigate();
  const { post: submitNotification } = usePost(fetchPostIwocapayNotifications);
  const { post: login, isLoading, error } = usePost(fetchPostPasswordLogin);

  const handleLogin = async (event: FormEvent) => {
    event.preventDefault();
    const isEmailValid = isValidEmail(email);
    if (!isEmailValid) {
      setIsEmailInvalid(true);
    }

    if (!password) {
      setIsPasswordInvalid(true);
    }

    if (isEmailValid && password) {
      try {
        await login({ data: { email, password } });
        redirectUser({ redirectUrl: getRedirectToLink() });
      } catch (e) {
        const lapiError = e as LapiError;
        reportIwocaPayBrokenAccount({ lapiError, email, submitNotification });
        determineTwoFactorRedirect({
          lapiError,
          shouldRedirect: (url) => {
            navigate(url);
          },
        });
      }
    }
  };

  return (
    <LoginContainer>
      <form
        data-testid="login-password-form"
        className={styles.loginWithPassword}
        onSubmit={handleLogin}
      >
        <Label htmlFor="email-input" className={styles.loginLabel}>
          <Trans>Email</Trans>
        </Label>
        <Input
          className="fs-exclude"
          data-testid="email-input"
          placeholder="name@provider.com"
          id="email-input"
          onChange={(event) => {
            setIsEmailInvalid(false);
            setEmail(event.target.value);
          }}
          onBlur={() => {
            if (!isValidEmail(email)) {
              setIsEmailInvalid(true);
            }
          }}
        />
        {isEmailInvalid ? (
          <AlertBox
            variant="failure"
            className={styles.errorBox}
            data-testid="error-message"
          >
            <Trans>Are you sure? This is not a valid email address</Trans>
          </AlertBox>
        ) : null}

        <Label
          className={cn(styles.loginLabel, styles.loginLabelSpacer)}
          htmlFor="password-input"
        >
          <Trans>Password</Trans>
        </Label>
        <Input
          className="fs-exclude"
          data-testid="password-input"
          type="password"
          placeholder="●●●●●●●●●●●●●●●●"
          id="password-input"
          onChange={(event) => {
            setIsPasswordInvalid(false);
            setPassword(event.target.value);
          }}
          onBlur={() => {
            if (!password) {
              setIsPasswordInvalid(true);
            }
          }}
        />
        {isPasswordInvalid ? (
          <AlertBox
            variant="failure"
            className={styles.passwordInvalidAlert}
            data-testid="error-message"
          >
            <Trans>Please enter your password</Trans>
          </AlertBox>
        ) : null}
        <Link className={styles.forgotPasswordLink} to="/login/password/reset">
          <Trans>Forgotten your password?</Trans>
        </Link>

        <Button
          variant="primary"
          disabled={isEmailInvalid || isPasswordInvalid || isLoading}
          type="submit"
          className={styles.submitButton}
        >
          {isLoading ? <LoadingSpinner /> : <Trans>Log in</Trans>}
        </Button>

        {error ? (
          <LoginErrorMessage
            error={error}
            copy={{
              loginEmailOrPassWrong: t`The email or password you entered is incorrect`,
              loginError: t`Sorry, something’s gone wrong. Try again later?`,
            }}
          />
        ) : null}

        <span className={styles.border}></span>

        <span className={styles.linkCopy} data-testid="link-to-login-link">
          <Trans>
            Or{" "}
            <Link
              className={styles.linkButton}
              to={`/login/passwordless/email${search}`}
            >
              email me a login link
            </Link>{" "}
            instead.
          </Trans>
        </span>
      </form>
    </LoginContainer>
  );
};

export default LoginWithPassword;
