import React from "react";

import { t } from "@lingui/core/macro";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/react/macro";

import TokenContainer from "./TokenContainer";
import styles from "./TokenLoading.module.css";
import loaderGif from "../../assets/TokenAssets/iwoca-loading.gif";

const TokenLoading: React.FunctionComponent = () => {
  const { i18n } = useLingui();
  return (
    <TokenContainer data-testid="token-loading-view">
      <img
        className={styles.loadingGif}
        src={loaderGif}
        alt={i18n._(t`Loading`)}
      />
      <div className={styles.infoText}>
        <Trans>Hold tight: we're taking you back to your account</Trans>
      </div>
    </TokenContainer>
  );
};

export default TokenLoading;
