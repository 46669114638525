import React, { useState } from "react";

import { useLocation, useParams } from "react-router";

import SetNewPasswordForm from "./SetNewPasswordForm";
import TokenVerification from "../../TokenViews/TokenVerification";

export type ResetPasswordTokenResponse = {
  data: {
    email: string;
  };
};
const ResetPassword = () => {
  const { token } = useParams();
  const location = useLocation();

  const [emailToResetPassword, setEmailToResetPassword] = useState<
    string | null
  >(null);

  if (!emailToResetPassword) {
    return (
      <TokenVerification<ResetPasswordTokenResponse>
        token={token || ""}
        verificationUrl="/api/lending/edge/password/reset/verify/"
        requestNewTokenUrl="/login/password/reset"
        onSuccess={(payload) => setEmailToResetPassword(payload?.data?.email)}
      />
    );
  }

  return (
    <SetNewPasswordForm
      email={emailToResetPassword}
      isSettingFirstPassword={location.pathname?.indexOf("/reset/") !== -1}
      token={token || ""}
    />
  );
};

export default ResetPassword;
