import { useEffect } from "react";

import { QueryClientProvider } from "@tanstack/react-query";

import { IwQueryClient } from "./IwQueryClient";

export const IwQueryClientProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  useEffect(() => {
    return () => IwQueryClient.destroy();
  });

  return (
    <QueryClientProvider client={IwQueryClient.getOrCreate()}>
      {children}
    </QueryClientProvider>
  );
};
