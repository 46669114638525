import React from "react";

import { Button } from "@iwoca/orion";
import cn from "classnames";

import tickSVG from "../../../assets/tick.svg";
import { redirectUser } from "../../authentication";
import { getRedirectToLink } from "../../LoginWithLink/getRedirectLink";
import styles from "../MultifactorAuth.module.css";

export const SuccessMultifactorAuth = () => {
  return (
    <div className={styles.modal}>
      <div className={cn(styles.textContainer, styles.centerText)}>
        <img src={tickSVG} alt="" className={styles.tickSymbol} />
        <h1 className={styles.headline}>You're all set</h1>
        <p className={styles.firstSubtitle}>
          You’ve added multi-factor authentication to your iwocaPay account
        </p>
        <p className={styles.secondSubtitle}>
          Next time you log in you’ll need to verify it’s you using your 6 digit
          code from your authenticator app.
        </p>
        <Button
          onClick={() => redirectUser({ redirectUrl: getRedirectToLink() })}
          className={styles.submitButton}
        >
          Go to my dashboard
        </Button>
      </div>
    </div>
  );
};
