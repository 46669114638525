import React, { useState, useCallback, FormEvent } from "react";

import { AlertBox, Button, Input, Label } from "@iwoca/orion";
import { Trans } from "@lingui/react/macro";
import cn from "classnames";

import NewPasswordApiError from "./NewPasswordApiError";
import { usePostApi } from "../../../fetchApi.hook";
import { redirectUser } from "../../authentication";
import styles from "../../CommonLogin.module.css";
import LoginContainer from "../../LoginContainer";
import { getRedirectToLink } from "../../LoginWithLink/getRedirectLink";

type ErrorResponse = {
  code: string;
  detail: string;
  meta?: any;
  source?: { parameter: "string"; pointer: "string" };
};
type ErrorPayload = {
  errors: ErrorResponse[];
};

type Props = {
  token: string;
  email: string;
  isSettingFirstPassword: boolean;
};
const SetNewPasswordForm: React.FunctionComponent<Props> = ({
  email,
  token,
  isSettingFirstPassword,
}) => {
  // only typed as error payload because when successful the customer will be redirected
  const { status, hasError, payload, triggerFetch } = usePostApi<ErrorPayload>({
    endpoint: "/api/lending/edge/password/reset/set-password/",
  });
  const [password, setPassword] = useState("");
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [showPasswordMatchError, setShowPasswordMatchError] = useState(false);

  const handleResetPassword = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (!password) {
        setIsPasswordInvalid(true);
      }

      const passwordsMatch = password === passwordConfirmation;
      if (!passwordsMatch) {
        setShowPasswordMatchError(true);
      }

      if (password && passwordsMatch) {
        const { hasError: requestFailed } = await triggerFetch({
          data: {
            password1: password,
            password2: passwordConfirmation,
            token,
          },
        });
        if (!requestFailed) {
          redirectUser({ redirectUrl: getRedirectToLink() });
        }
      }
    },
    [password, passwordConfirmation, token, triggerFetch],
  );

  return (
    <LoginContainer>
      <form className={styles.loginWithPassword} onSubmit={handleResetPassword}>
        <Label htmlFor="email-input" className={styles.loginLabel}>
          <Trans>
            {isSettingFirstPassword ? "Resetting" : "Setting"} a new password
            for
          </Trans>
        </Label>
        <Input
          className="fs-exclude"
          data-testid="email-input"
          placeholder="name@provider.com"
          disabled={true}
          value={email}
          id="email-input"
        />

        <p className={styles.instructionsLong}>
          <Trans>
            Your password must be at least 8 characters long, with at least 1
            upper case, 1 lower case and 1 number.
          </Trans>
        </p>

        <Label htmlFor="new_password" className={styles.loginLabel}>
          <Trans>Your new password</Trans>
        </Label>
        <Input
          className="fs-exclude"
          type="password"
          placeholder="●●●●●●●●●●●●●●●●"
          id="new_password"
          autoComplete="new-password"
          onChange={(event) => {
            setIsPasswordInvalid(false);
            setPassword(event.target.value);
          }}
          onBlur={() => {
            if (!password) {
              setIsPasswordInvalid(true);
            }
          }}
        />

        {hasError && status === 400 ? (
          <AlertBox
            variant="failure"
            className={styles.passwordInvalidAlert}
            data-testid="error-message"
          >
            <NewPasswordApiError
              errorCode={payload?.errors?.[0]?.code || "default"}
            />
          </AlertBox>
        ) : null}

        {isPasswordInvalid ? (
          <AlertBox
            variant="failure"
            className={styles.passwordInvalidAlert}
            data-testid="error-message"
          >
            <Trans>Please enter a new password</Trans>
          </AlertBox>
        ) : null}

        <Label
          htmlFor="confirm_new_password"
          className={cn(styles.loginLabel, styles.loginLabelSpacer)}
        >
          <Trans>Confirm your new password</Trans>
        </Label>
        <Input
          className="fs-exclude"
          type="password"
          placeholder="●●●●●●●●●●●●●●●●"
          id="confirm_new_password"
          autoComplete="new-password"
          onChange={(event) => {
            setPasswordConfirmation(event.target.value);
            setShowPasswordMatchError(false);
          }}
          onBlur={() => {
            if (password !== passwordConfirmation) {
              setShowPasswordMatchError(true);
            }
          }}
        />

        {hasError && status !== 400 ? (
          <AlertBox
            variant="failure"
            className={styles.passwordInvalidAlert}
            data-testid="error-message"
          >
            <Trans>Sorry, something’s gone wrong. Try again later?</Trans>
          </AlertBox>
        ) : null}

        {showPasswordMatchError ? (
          <AlertBox
            variant="failure"
            className={styles.passwordInvalidAlert}
            data-testid="error-message"
          >
            <Trans>Passwords must be the same</Trans>
          </AlertBox>
        ) : null}

        <div className={styles.submitContainer}>
          <Button
            variant="primary"
            type="submit"
            className={styles.submitButtonFull}
          >
            <Trans>Set password and log in</Trans>
          </Button>
        </div>
      </form>
    </LoginContainer>
  );
};

export default SetNewPasswordForm;
