import React from "react";

type Props = {
  className?: string;
};
const TokenExpiredSVG: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.5" cx="120" cy="120" r="120" fill="#EEF3F7" />
      <path
        d="M120.338 49.709C159.184 49.709 190.967 81.4968 190.967 120.349C190.967 159.2 159.184 190.988 120.338 190.988C81.4921 190.988 49.7092 159.2 49.7092 120.349"
        stroke="#0C2340"
        strokeWidth="6"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M120.349 68.5469V120.349L92.093 134.477"
        stroke="#FB534A"
        strokeWidth="6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.6522 101.007C53.9989 101.007 55.0906 99.9147 55.0906 98.5679C55.0906 97.221 53.9989 96.1289 52.6522 96.1289C51.3055 96.1289 50.2139 97.221 50.2139 98.5679C50.2139 99.9147 51.3055 101.007 52.6522 101.007Z"
        fill="#FB534A"
        stroke="#FB534A"
        strokeWidth="3"
      />
      <path
        d="M69.3843 73.7603C70.7774 73.7603 71.9068 72.6684 71.9068 71.3215C71.9068 69.9747 70.7774 68.8828 69.3843 68.8828C67.9912 68.8828 66.8618 69.9747 66.8618 71.3215C66.8618 72.6684 67.9912 73.7603 69.3843 73.7603Z"
        fill="#FB534A"
        stroke="#FB534A"
        strokeWidth="3"
      />
      <path
        d="M96.627 56.1011C98.02 56.1011 99.1493 55.0093 99.1493 53.6623C99.1493 52.3155 98.02 51.2236 96.627 51.2236C95.2335 51.2236 94.1042 52.3155 94.1042 53.6623C94.1042 55.0093 95.2335 56.1011 96.627 56.1011Z"
        fill="#FB534A"
        stroke="#FB534A"
        strokeWidth="3"
      />
    </svg>
  );
};

export default TokenExpiredSVG;
