import { PostIwocapayNotificationsRequestBody } from "@iwoca/lapi-client/edge";
import { LapiError } from "@iwoca/lapi-client/lendingAPI";

export type TwoFactorErrorResponse = {
  code: string;
  detail: string;
};

export type NetworkError = {
  message: string;
};

export const parseError = (error: string) => {
  try {
    return JSON.parse(error).errors[0] as TwoFactorErrorResponse;
  } catch (e) {}
  return null;
};

export const determineTwoFactorRedirect = ({
  lapiError,
  shouldRedirect,
}: {
  lapiError: LapiError;
  shouldRedirect: (url: string) => void;
}) => {
  try {
    const primaryError = JSON.parse(lapiError.message).errors[0];

    const searchParams = new URLSearchParams(window.location.search);
    if (primaryError.code === "TwoFactorSetupRequired") {
      searchParams.set("k", primaryError.meta.authentication_token);
      shouldRedirect(
        `/login/two-factor/setup/?${decodeURIComponent(
          searchParams.toString(),
        )}`,
      );
    } else if (primaryError.code === "TwoFactorAuthenticationRequired") {
      searchParams.set("k", primaryError.meta.authentication_token);
      shouldRedirect(
        `/login/two-factor/authenticate/?${decodeURIComponent(
          searchParams.toString(),
        )}`,
      );
    }
  } catch (e) {}
};

export const requiresTwoFactor = (lapiError: LapiError) => {
  try {
    const primaryError = JSON.parse(lapiError.message).errors[0];

    const searchParams = new URLSearchParams(window.location.search);
    if (primaryError.code === "TwoFactorSetupRequired") {
      searchParams.set("k", primaryError.meta.authentication_token);
      return {
        action: "setup",
        url: `/login/two-factor/setup/?${decodeURIComponent(
          searchParams.toString(),
        )}`,
        authToken: primaryError.meta.authentication_token,
      };
    } else if (primaryError.code === "TwoFactorAuthenticationRequired") {
      searchParams.set("k", primaryError.meta.authentication_token);
      return {
        action: "authenticate",
        url: `/login/two-factor/authenticate/?${decodeURIComponent(
          searchParams.toString(),
        )}`,
        authToken: primaryError.meta.authentication_token,
      };
    } else {
      return {
        action: "none",
        url: null,
        authToken: null,
      };
    }
  } catch (e) {
    return {
      action: "none",
      url: null,
      authToken: null,
    };
  }
};

export const reportIwocaPayBrokenAccount = ({
  lapiError,
  email,
  submitNotification,
}: {
  lapiError: LapiError;
  email: string;
  submitNotification: (data: PostIwocapayNotificationsRequestBody) => void;
}) => {
  try {
    const primaryError = JSON.parse(lapiError.message).errors[0];

    if (primaryError.code === "MultipleIwocaPaySellers") {
      if (sessionStorage.notifyBrokenAccount) return;
      sessionStorage.notifyBrokenAccount = true;
      submitNotification({
        data: {
          type: "broken-account",
          attributes: { email: email },
        },
      });
    }
  } catch (e) {}
};
